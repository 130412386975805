import React from 'react';

function VerifyUrl() {
  return (
    <div className="verify_url">
      <div className="heading">Welcome to MYNUU</div>
      <div className="description">Verify your url and try again</div>
      <div className="logo">MYNUU</div>
    </div>
  );
}

export default VerifyUrl;
